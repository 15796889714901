.hero-section {
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(3px);
  background-size: cover;
  z-index: -1;
  backdrop-filter: blur(3px);
  -webkit-filter: blur(3px);
}

.hero-section::before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
}
