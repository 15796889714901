@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  
}

.bg-secondary{
  background-color: rgb(30, 58, 138);
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}
.propertyhero {
  background: url(./images/propertyhero.webp) top center no-repeat;
  background-size: cover;
}

.footer {
  background: rgb(30, 58, 138) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(https://cornerstonemarketing.ca/wordpress/wp-content/uploads/2024/02/Home-on-Power-Brochure-2.jpg) fixed center center;
}


#new_properties_page

html {
  scroll-behavior: smooth;
}


button {
  letter-spacing: 2px;
}

/* Add special styles to the active link */
.header-links li a.active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -140%;
  background-color: #dcac36;
  width: 100%;
  height: 2px;
}

@media (max-width: 780px) {
  .header-links li a.active::before {
    bottom: -70%;
  }
}

.main-btn:hover .arrow {
  display: block;
  right: 0;
}
